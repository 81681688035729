@font-face {
  font-family: 'NunitoSans';
  src: url('/public/fonts/NunitoSans.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Nunito';
  src: url('/public/fonts/Nunito.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}